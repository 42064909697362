import React from 'react';
import Mtsc from './images/mtsc.svg';

const MENUS = [
  {
    id: 'home',
    name: '首页',
  },
  {
    id: 'products',
    name: '产品矩阵',
    showIcon: true,
    children: [
      { id: '/marketingcloud', name: 'Convertlab 营销云', type: 'parent' },
      { id: '/dmhub', name: 'DM Hub 营销自动化平台', iconId: 'dmhub' },
      { id: '/cdp', name: 'Data Hub 客户数据平台', iconId: 'datahub' },
      { id: '/adhub', name: 'Ad Hub 智能广告平台', iconId: 'adhub' },
      {
        id: '/pechub',
        noPage: false,
        name: 'PEC 隐私计算平台',
        // type: 'no-page',
        iconId: 'pechub',
      },
      { id: '/aihub', name: 'AI Hub 数据智能引擎', iconId: 'aihub' },
      { id: '/openhub', name: 'Open Hub 开放平台', iconId: 'openhub' },
      {
        id: 'https://www.huiju.cool',
        name: 'B2B荟聚数字营销云',
        target: '_blank',
        rel: 'noreferrer noopener',
        iconId: 'huiju',
      },
    ],
  },
  {
    id: 'scens',
    name: '应用场景',
    children: [
      { id: '/digitalmarketing', name: '数字化营销' },
      { id: '/privatetraffic', name: '私域流量运营' },
      { id: '/scrm', name: 'SCRM' },
      { id: '/campaign', name: '营销活动管理' },
      { id: '/royalty', name: '会员营销' },
      { id: '/personas', name: '用户画像' },
      { id: '/tags', name: '标签体系' },
    ],
  },
  {
    id: 'solutions',
    name: '行业方案',
    children: [
      { id: '/brandretail', name: '品牌零售行业解决方案' },
      { id: '/modernretail', name: '现代零售行业解决方案' },
      { id: '/fmcg', name: '快消行业解决方案' },
      { id: '/durables', name: '耐消行业解决方案' },
      { id: '/financial', name: '金融行业解决方案' },
      { id: '/b2b', name: 'B2B行业解决方案' },
    ],
  },
  {
    id: 'sources',
    name: '工具栈',
    children: [
      {
        id: 'http://martechwiki.convertlab.com',
        name: 'MarTech Wiki',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      {
        id: 'https://api-docs.convertlab.com/v2/restapi/overview',
        name: 'API文档',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      {
        id: 'https://www.convertlab.com/updateInfo',
        name: '产品更新',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        // id: '/updateinfo',
        // name: '产品更新',
      },
      { id: '/yourtitles', name: '智能营销主题生成器' },
    ],
  },
  {
    id: 'blogs',
    name: '资讯中心',
    children: [
      { id: '/blogs', name: '洞察 | MarTech 增长笔记' },
      { id: '/ccmeaningful', name: '视频资源 | CC有料' },
      { id: '/whitepaper', name: '白皮书下载' },
      { id: '/lifeCycle', name: '产品生命周期说明', href: 'https://host.convertlab.com/page/1238467299/7d3fa761f91544d6a1ccccacda18209e', target: '_blank' },
    ],
  },
  {
    id: 'aboutus',
    name: '关于我们',
    children: [
      { id: '/about', name: '关于我们' },
      { id: '/about/#contacts', name: '联系我们' },
      {
        id: 'https://app.mokahr.com/social-recruitment/convertlab/54112',
        name: '加入我们',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      { id: '/partner', name: '合作伙伴' },
    ],
  },
];

export default MENUS;
export { MENUS };



// 产品更新记录，原来是一个后端渲染的HTML，3.1后更新记录放在前端 @Maggie,Wu
export const updateHistory = [
  {
    "title": "DM Hub 3.2版本更新",
    "briefs": "新版营销活动 2.0 | UDO 微信触达|企微流失管理|企微权限",
    "publishDate": "2024.07.05",
    "id": "dm3.2",
    "hyperLink": 'https://host.convertlab.com/page/6252678369460111857/f3f0f2ec4e87470784ead8499b5bf5b2',
    "details": []
  },
  {
    "title": "DM Hub 3.1版本更新",
    "briefs": "新版自定义对象|新版数据权限｜国际短信｜内容模板AIGC｜员工智能任务｜平台能力增强",
    "publishDate": "2024.03.23",
    "id": "dm3.1",
    "hyperLink": 'https://host.convertlab.com/page/6252678369460111857/f3f0f2ec4e87470784ead8499b5bf5b2',
    "details": []
  },
  {
    "title": "DM Hub 2.10版本更新",
    "publishDate": "2023.07.03",
    "briefs": "智能员工任务｜员工营销工具｜短信｜彩信｜超级短信｜数据看板｜数据看板｜Webhook",
    "id": "545",
    "details": []
  },
  {
    "title": "DM Hub 2.9版本更新",
    "briefs": "短信｜彩信｜超级短信｜数据看板｜小程序订阅通知｜高级筛选｜分析中心",
    "publishDate": "2023.04.18",
    "id": "537",
    "details": []
  },
  {
    "title": "DM Hub 2.8版本更新",
    "briefs": "彩信｜超级短信｜微信｜企业微信｜数据安全｜系统监控｜微页面",
    "publishDate": "2023.02.02",
    "id": "529",
    "details": []
  },
  {
    "title": "DM Hub 2.7版本更新",
    "briefs": "产品视觉与用户体验 | 客户数据合规 | 自动流监控 | 微页面 | 数据采集加参",
    "publishDate": "2022.10.11",
    "id": "521",
    "details": []
  },
  {
    "title": "DM Hub 2.6版本更新",
    "briefs": "产品视觉与用户体验 | 微信 | 邮件 | 自动流程 | 分析",
    "publishDate": "2022.07.01",
    "id": "513",
    "details": []
  },
  {
    "title": "DM Hub 2.5版本更新",
    "briefs": "小程序一人一链 | 自动流 | 企业微信 | 分析中心 | 客户分层 | 彩信 | 其他",
    "publishDate": "2022.04.19",
    "id": "505",
    "details": []
  },
  {
    "title": "DM Hub 2.4版本更新",
    "briefs": "自动流策略器 | 微信群发 | 邮件 | 短信 | 表单 | 自动流程 | 企业微信 | 其他",
    "publishDate": "2022.03.08",
    "id": "497",
    "details": []
  },
  {
    "title": "DM Hub 2.3版本更新",
    "briefs": "线索管理 | 微信公众号 | 微信群发 | 微页面 | 表单 | 自动流程",
    "publishDate": "2022.01.11",
    "id": "489",
    "details": []
  },
  {
    "title": "DM Hub 2.2版本更新",
    "briefs": "素材管理 | 短消息 | 表单 | 微信公众号 | 自动流程 | 抖音插件",
    "publishDate": "2021.11.30",
    "id": "481",
    "details": []
  },
  {
    "title": "DM Hub 2.1版本更新",
    "briefs": "表单 | APP消息推送 | 超级短信插件 | 员工营销工具插件",
    "publishDate": "2021.10.27",
    "id": "473",
    "details": []
  },
  {
    "title": "DM Hub 2.0版本更新",
    "briefs": "微页面 | 员工营销工具插件 | 抖音插件",
    "publishDate": "2021.09.28",
    "id": "465",
    "details": []
  },
  {
    "title": "DM Hub 1.92版本更新",
    "briefs": "账号安全 | 数据监测 | 表单 | 员工营销工具 | 快手小店数据同步插件 | 其他",
    "publishDate": "2021.08.24",
    "id": "457",
    "details": []
  },
  {
    "title": "DM Hub 1.91版本更新",
    "briefs": "员工营销插件 | 有赞插件 | 抖音插件 | 微信公众号 | 分析中心 | 其他",
    "publishDate": "2021.07.27",
    "id": "449",
    "details": []
  },
  {
    "title": "DM Hub 1.90版本更新",
    "briefs": "小程序订阅通知 | 群组 | 表单 | 微信 | 分析中心",
    "publishDate": "2021.06.25",
    "id": "441",
    "details": []
  },
  {
    "title": "DM Hub 1.89版本更新",
    "briefs": "APP | 表单 | 商户中心 | 自动流 | 分析中心",
    "publishDate": "2021.05.26",
    "id": "433",
    "details": []
  },
  {
    "title": "DM Hub 1.88版本更新",
    "briefs": "自动流 | 表单 | 短信 | 微信公众号",
    "publishDate": "2021.04.27",
    "id": "425",
    "details": []
  },
  {
    "title": "DM Hub 1.87版本更新",
    "briefs": "公众号｜表单｜邮件",
    "publishDate": "2021.03.31",
    "id": "417",
    "details": []
  },
  {
    "title": "DM Hub 1.85版本更新",
    "briefs": "小程序｜表单｜微信公众号｜自动流",
    "publishDate": "2021.02.02",
    "id": "409",
    "details": []
  },
  {
    "title": "DM Hub 1.84版本更新",
    "briefs": "短消息｜图文群发｜微页面｜营销应用",
    "publishDate": "2021.01.05",
    "id": "401",
    "details": []
  },
  {
    "title": "DM Hub 1.83版本更新",
    "briefs": "表单｜APP Push｜微信公众号｜站内信｜客户标签",
    "publishDate": "2020.12.08",
    "id": "393",
    "details": []
  },
  {
    "title": "DM Hub 1.82版本更新",
    "briefs": "自动流程｜站内信｜表单｜视频短信营销应用｜设置中心",
    "publishDate": "2020.11.13",
    "id": "385",
    "details": []
  },
  {
    "title": "DM Hub 1.81版本更新",
    "briefs": "微信公众号｜高级筛选｜设置中心｜有赞插件｜企业微信｜分析中心",
    "publishDate": "2020.09.25",
    "id": "377",
    "details": []
  },
  {
    "title": "DM Hub 1.80版本更新",
    "briefs": "UI全新改版｜数据安全｜分析中心｜用户管理｜数据权限｜插件",
    "publishDate": "2020.08.28",
    "id": "369",
    "details": []
  },
  {
    "title": "DM Hub 1.79版本更新",
    "briefs": "邮件模版功能｜自动流程｜分析中心｜标签｜表单｜微页面｜在用商品",
    "publishDate": "2020.07.20",
    "id": "361",
    "details": []
  },
  {
    "title": "DM Hub1.78",
    "briefs": "SSO登录（单点登录）| 客户标签 | 营销应用 | 消息防骚扰 | 审批流程 | 会员 | 内容标签 | 营销活动",
    "publishDate": "2020.06.21",
    "id": "353",
    "details": []
  },
  {
    "title": "DM Hub1.77版本更新",
    "briefs": "操作日志 | 会话 | 审批流程 | 客户 | 智能群组 | 智能标签 | 自动流程 | 营销活动 | 消息防骚扰 | 分享裂变 | 抽奖活动 | 表单 | 微页面",
    "publishDate": "2020.05.19",
    "id": "345",
    "details": []
  },
  {
    "title": "DM Hub1.76版本更新",
    "briefs": "表单功能 | 文件管理 | 分析中心 | APP Push | 微页面 | 设置中心 | 客户| 应用插件| 其他",
    "publishDate": "2020.04.17",
    "id": "329",
    "details": []
  },
  {
    "title": "DM Hub 1.75版本更新",
    "briefs": "营销应用 | 分析 | 微页面 | 微信公众号 | 营销活动 | 来源 | APP消息推送",
    "publishDate": "2020.03.18",
    "id": "321",
    "details": []
  },
  {
    "title": "DM Hub 1.74版本更新",
    "briefs": "会员 | 分析 | 抽奖 | 在用商品 | 自动流程 | 属性设置 | APP消息推送",
    "publishDate": "2020.02.13",
    "id": "297",
    "details": []
  },
  {
    "title": "DM Hub 1.73版本更新",
    "briefs": "营销活动 | 群组&标签 | 分析 | 属性 | 权限",
    "publishDate": "2019.12.31",
    "id": "225",
    "details": []
  },
  {
    "title": "DM Hub 1.72版本更新",
    "briefs": "分析 | 客户来源 | 微页面 | 营销活动 | 自定义事件 | 群组 |",
    "publishDate": "2019.11.29",
    "id": "137",
    "details": []
  },
  {
    "title": "DM Hub 1.71版本更新",
    "briefs": "分析 | 微信图文 | 微信二维码 | 用户 |",
    "publishDate": "2019.11.01",
    "id": "129",
    "details": []
  },
  {
    "title": "DM Hub 1.70版本更新",
    "briefs": "客户|高级筛选|微信|微页面|分析|金数据插件",
    "publishDate": "2019.09.26",
    "id": "113",
    "details": []
  },
  {
    "title": "DM Hub 1.69版本更新",
    "briefs": "营销应用插件 | 高级筛选 | 用户管理 | 分析 | 自动流程 | 设置",
    "publishDate": "2019.08.29",
    "id": "105",
    "details": []
  },
  {
    "title": "DM Hub 1.68版本更新",
    "briefs": "微信公众号 | 微页面 | 用户管理 | 分析 | 自动流程 | 设置",
    "publishDate": "2019.08.01",
    "id": "97",
    "details": []
  },
  {
    "title": "DM Hub 1.67版本更新",
    "briefs": "导航栏 | 首页 | 微信公众号 | 客户 | 分析 | 会员 | 自动流程",
    "publishDate": "2019.07.03",
    "id": "89",
    "details": []
  },
  {
    "title": "DM Hub 1.66版本更新",
    "briefs": "分析 | 客户 | 高级筛选 | 全员推广 | 会员",
    "publishDate": "2019.06.03",
    "id": "81",
    "details": []
  },
  {
    "title": "DM Hub 1.65版本更新",
    "briefs": "客户 | 自动流程 | 抽奖活动 | 分享裂变 | 分析中心",
    "publishDate": "2019.04.28",
    "id": "73",
    "details": []
  },
  {
    "title": "DM Hub 1.64版本更新",
    "briefs": "会员 |小程序 | 媒体数据 | 模型标签",
    "publishDate": "2019.03.28",
    "id": "65",
    "details": []
  },
  {
    "title": "DM Hub 1.63版本更新",
    "briefs": "客户 |分析中心 | 微页面 | 全员推广",
    "publishDate": "2019.02.26",
    "id": "57",
    "details": []
  },
  {
    "title": "DM Hub 1.62版本更新",
    "briefs": "全员推广 | 分享裂变 | 微信红包 | 内容标签 |",
    "publishDate": "2019.01.14",
    "id": "49",
    "details": []
  },
  {
    "title": "DM Hub 1.61版本更新",
    "briefs": "标签 | 客户 | 微信 | 短信 |",
    "publishDate": "2018.12.10",
    "id": "41",
    "details": []
  },
  {
    "title": "DM Hub 1.60版本更新",
    "briefs": "企业微信 | 全员推广 | Webhook | 标签 |",
    "publishDate": "2018.11.12",
    "id": "33",
    "details": []
  },
  {
    "title": "DM Hub 1.59版本更新",
    "briefs": "登录页 | 分析 | 自动流程 | 任务 |",
    "publishDate": "2018.09.27",
    "id": "25",
    "details": []
  },
  {
    "title": "DM Hub 1.58版本更新",
    "briefs": "订单 | 自动流程 | 微信 |",
    "publishDate": "2018.08.14",
    "id": "23",
    "details": []
  },
  {
    "title": "DM Hub 1.57版本更新",
    "briefs": "小程序 | 微信 | 自动流程 | 分析 |",
    "publishDate": "2018.07.13",
    "id": "22",
    "details": []
  },
  {
    "title": "DM Hub 1.56版本更新",
    "briefs": "自动流程 | 微信 | 客户 | 分析 |",
    "publishDate": "2018.06.14",
    "id": "21",
    "details": []
  },
  {
    "title": "DM Hub 1.55版本更新",
    "briefs": "企业微信 | 自动流程 | 客户 | 分析 |",
    "publishDate": "2018.05.23",
    "id": "20",
    "details": []
  },
  {
    "title": "DM Hub 1.54版本更新",
    "briefs": "客户合并 | 高级筛选 | 分析",
    "publishDate": "2018.04.24",
    "id": "19",
    "details": []
  },
  {
    "title": "DM Hub 1.53版本更新",
    "briefs": "数据看板 | 群组 | 高级筛选",
    "publishDate": "2018.03.26",
    "id": "18",
    "details": []
  },
  {
    "title": "DM Hub 1.52版本更新",
    "briefs": "权益码 | 活动蓝图 | 微页面 | 分析 |全员推广",
    "publishDate": "2018.02.27",
    "id": "17",
    "details": []
  },
  {
    "title": "DM Hub 1.51版本更新",
    "briefs": "会员 | App触点 | 自动流程 | 微页面 | 分析",
    "publishDate": "2018.01.24",
    "id": "16",
    "details": []
  },
  {
    "title": "DM Hub 1.50版本更新",
    "briefs": "自动流程 | 微信 | 微页面 | 数据看板",
    "publishDate": "2017.12.28",
    "id": "15",
    "details": []
  },
  {
    "title": "DM Hub 1.49版本更新",
    "briefs": "分析 | 自动流程 | 标签 | 微页面",
    "publishDate": "2017.11.30",
    "id": "14",
    "details": []
  },
  {
    "title": "DM Hub 1.48版本更新",
    "briefs": "首页 | 标签 | 分析 | 邮件",
    "publishDate": "2017.10.31",
    "id": "13",
    "details": []
  },
  {
    "title": "DM Hub 1.47版本更新",
    "briefs": "微信 | 自动流程 | 公司网站 | 全员推广",
    "publishDate": "2017.09.28",
    "id": "12",
    "details": []
  },
  {
    "title": "DM Hub 1.46版本更新",
    "briefs": "邮件 | 订单分析",
    "publishDate": "2017.08.31",
    "id": "9",
    "details": []
  },
  {
    "title": "DM Hub 1.45版本更新",
    "briefs": "自动流程2.0 | 订单分析 | 新版客户导入",
    "publishDate": "2017.08.08",
    "id": "7",
    "details": []
  },
  {
    "title": "DM Hub 1.44版本更新",
    "briefs": "全员推广 | 微信 | 微页面2.0 | 短信",
    "publishDate": "2017.07.15",
    "id": "4",
    "details": []
  },
  {
    "title": "DM Hub 1.43版本更新",
    "briefs": "数据看板 | 短信 | 高级筛选 | 微页面 | 客户属性",
    "publishDate": "2017.06.15",
    "id": "3",
    "details": []
  },
  {
    "title": "DM Hub 1.42版本更新",
    "briefs": "新版漏斗分析 | 智能群组 | 自动流程 | 微页面2.0 | 新版分析中心",
    "publishDate": "2017.05.17",
    "id": "2",
    "details": []
  }
]